import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import IndexWebProgramming from './pages/index/index-web-programming';
import SimpleCookieTermsPage from './pages/utility/SimpleCookieTerms';
import SimplePrivacyPage from './pages/utility/SimplePrivacyPage';

export default function AppRouter() {
    return (
        <Routes>
            <Route path="/" element={<IndexWebProgramming/>} />
            <Route path="/privacy-policy" element={<SimplePrivacyPage/>} />
            <Route path="/cookie-policy" element={<SimpleCookieTermsPage/>} />
        </Routes>
    )
}