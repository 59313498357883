import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './AppRouter';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-F3BDP1QD4M');

export default function App() {
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    return (
        <BrowserRouter>
            <AppRouter/>
        </BrowserRouter>
    )
}