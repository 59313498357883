import React from 'react';
import TermlyCookiePolicy from './TermlyCookiePolicy.html';

const SimpleCookieTermsPage = () => {
  return (
    <div dangerouslySetInnerHTML={{ __html: TermlyCookiePolicy }} />
  );
};

export default SimpleCookieTermsPage;
