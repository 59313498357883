import { FiCalendar, FiCodesandbox, FiDollarSign, FiShield, FiThumbsUp, FiUser } from "react-icons/fi";
import { LuFileJson2, LuStore, LuAppWindow } from "react-icons/lu";

import clener1 from '../assets/images/cleaner/1.jpg'
import clener2 from '../assets/images/cleaner/2.jpg'
import clener3 from '../assets/images/cleaner/3.jpg'
import clener4 from '../assets/images/cleaner/4.jpg'
import clener5 from '../assets/images/cleaner/5.jpg'
import clener6 from '../assets/images/cleaner/6.jpg'

import team1 from '../assets/images/client/04.jpg'
import team2 from '../assets/images/client/05.jpg'
import team3 from '../assets/images/client/06.jpg'
import team4 from '../assets/images/client/07.jpg'
import { BiMailSend } from "react-icons/bi";

export const webServices = [
    {
        icon: LuAppWindow,
        title: 'Fleet Manager',
        desc: 'A single portal for all your software, wherever its hosted. Secured, audited, and easy to use'
    },
    {
        icon: LuStore,
        title: 'Marketplace',
        desc: 'Find the software you need, and all its dependencies. One click install with no hassle'
    },
    {
        icon: LuFileJson2,
        title: 'Developer Portal',
        desc: 'Build and package your software for Day 1 through N and collaborate with your entire team'
    }

]

export const faqData = [
    {
        id: 1,
        title: 'How do I get started?',
        desc: 'Sign up for a free account and start using the Vega Manager today!'
    },
    {
        id: 2,
        title: 'How much can I save?',
        desc: '85%'
    },
]

export const cleaningServices = [
    {
        id: 1,
        icon: FiCodesandbox,
        title: 'Latest Equipments',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id: 2,
        icon: FiDollarSign,
        title: 'Affordable Service',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id: 3,
        icon: FiUser,
        title: 'Expert Team',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id: 4,
        icon: FiThumbsUp,
        title: 'Quality Work',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit'
    },
]

export const cleanerCounter = [
    {
        title: 'Satisfied Client',
        value: 1548
    },
    {
        title: 'Expert Team',
        value: 25
    },
    {
        title: 'Years Experience',
        value: 9
    },
]

export const clenerServices = [
    {
        image: clener1,
        title: 'House Cleaning',
        feature: ['Vacuming', 'Bathroom Cleaning', 'Window Cleaning', 'Bedrooms Cleaning']
    },
    {
        image: clener2,
        title: 'Office Cleaning',
        feature: ['Vacuming', 'Bathroom Cleaning', 'Window Cleaning', 'Bedrooms Cleaning']
    },
    {
        image: clener3,
        title: 'Kitchen Cleaning',
        feature: ['Vacuming', 'Bathroom Cleaning', 'Window Cleaning', 'Bedrooms Cleaning']
    },
    {
        image: clener4,
        title: 'Window Cleaning',
        feature: ['Vacuming', 'Bathroom Cleaning', 'Window Cleaning', 'Bedrooms Cleaning']
    },
    {
        image: clener5,
        title: 'Carpet Cleaning',
        feature: ['Vacuming', 'Bathroom Cleaning', 'Window Cleaning', 'Bedrooms Cleaning']
    },
    {
        image: clener6,
        title: 'Car Cleaning',
        feature: ['Vacuming', 'Bathroom Cleaning', 'Window Cleaning', 'Bedrooms Cleaning']
    },
]

export const clenerTeam = [
    {
        image: team1,
        name: 'Jack John',
        desc: 'Cleaner'
    },
    {
        image: team2,
        name: 'Krista John',
        desc: 'Cleaner'
    },
    {
        image: team3,
        name: 'Roger Jackson',
        desc: 'Cleaner'
    },
    {
        image: team4,
        name: 'Johnny English',
        desc: 'Cleaner'
    },
]

export const clenerBlog = [
    {
        image: clener1,
        title: 'Design your apps in your own way',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        image: clener2,
        title: 'How apps is changing the IT world',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        image: clener3,
        title: 'Smartest Applications for Business',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit'
    },
]

export const servicesData = [
    {
        id: 1,
        icon: FiShield,
        title: 'Secured Payment',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id: 2,
        icon: FiCalendar,
        title: 'Easy to use',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit'
    },
    {
        id: 3,
        icon: BiMailSend,
        title: 'Support',
        desc: 'The phrasal sequence of the is now so that many campaign and benefit'
    },
]

