// src/components/PagePrivacy.js
import React from 'react';
import TermlyPrivacyPolicy from './TermlyPrivacyPolicy.html';

const SimplePrivacyPage = () => {
  return (
    <div dangerouslySetInnerHTML={{ __html: TermlyPrivacyPolicy }} />
  );
};

export default SimplePrivacyPage;
