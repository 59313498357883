import React from 'react'
import { Link } from 'react-router-dom';

import logo_light from '../../assets/images/logo-light.png';

import { FaGithub, FaLinkedin, FaMediumM, FaRegEnvelope, FaFunnelDollar } from 'react-icons/fa';
import { MdKeyboardArrowRight } from 'react-icons/md';

export default function VegaFooter() {
    return (
    <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
        <div className="container relative">
            <div className="grid grid-cols-12">
                <div className="col-span-12">
                    <div className="py-[60px] px-0">
                        <div className="flex flex-col md:flex-row justify-between gap-[30px]">
                            <div className="lg:col-span-4 md:col-span-12">
                                <Link to="/#" className="text-[22px] focus:outline-none">
                                    <img src={logo_light} alt="" />
                                </Link>
                                <p className="mt-6 text-gray-300">Adopt *aaS Faster. Cheaper. Smarter.</p>
                                <ul className="list-none mt-5 space-x-1 space-y-1">
                                    <li className="inline"><Link to="https://www.linkedin.com/company/archetypical-software" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaLinkedin className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="https://medium.com/archetypical-software" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaMediumM className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="https://github.com/ArchetypicalSoftware" target="_blank" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaGithub className='text-sm'/></Link></li>
                                    <li className="inline"><Link to="mailto:development@archetypical.software" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaRegEnvelope  className=" text-sm"/></Link></li>
                                    <li className="inline"><Link to="https://www.fundable.com/archetypical-software-vega" className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaFunnelDollar  className=" text-sm"/></Link></li>
                                </ul>
                            </div>

                            <div className="lg:col-span-3 md:col-span-4">
                                <h5 className="tracking-[1px] text-gray-100 font-semibold">Useful Links</h5>
                                <ul className="list-none footer-list mt-6">
                                    <li><Link to="/privacy-policy" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight className="text-xl me-1"/>Privacy Policy</Link></li>
                                    <li><Link to="/cookie-policy" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight className="text-xl me-1"/>Cookie Policy</Link></li>
                                </ul>
                            </div>

                            <div className="lg:col-span-3 md:col-span-4">
                                <h5 className="tracking-[1px] text-gray-100 font-semibold">Contact Us</h5>
                                <p className="mt-6">We would love to hear from you!</p>
                                <div className="grid grid-cols-2 gap-4">
                                    <button onClick={() => window.open('https://calendly.com/raj-archetypical', '_blank')} className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Book a demo</button>
                                    <button onClick={() => window.open('https://www.fundable.com/archetypical-software-vega', '_blank')} className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Invest</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="py-[30px] px-0 border-t border-slate-800">
            <div className="container relative text-center">
                <div className="grid md:grid-cols-2 items-center">
                    <div className="md:text-start text-center">
                        <p className="mb-0">© {new Date().getFullYear()} Archetypical Software</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    )
}
